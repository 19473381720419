import React from 'react';
import App, { Container } from 'next/app';
import Head from 'next/head'

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  render() {
    let { Component, pageProps } = this.props
    let getLayout = Component.getLayout || ((page, pageProps) => page );

    return (
      <div>
        <Head>
          <meta charSet="UTF-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta httpEquiv="X-UA-Compatible" content="ie=edge"/>
          <link href="/libs/fontAwesome/css/font-awesome.css" rel="stylesheet"></link>
          <link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&amp;display=swap" rel="stylesheet"/>
          <link rel="stylesheet" type="text/css" href="/libs/dateRangePicker/daterangepicker.css"/>
        </Head>
        <style global jsx>{`
          html, body{
            margin:0;
            padding:0;
            width: 100%;
            height: 100%;
            position: relative;
            font-family: 'Roboto', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          #__next{
              width: 100%;
              height: 100%;
          }
          .hide{
              display: none !important;
          }
        `}</style>
        {getLayout(<Component {...pageProps}></Component>, pageProps)}
      </div>
    );
  }
}

export default MyApp;